import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import "../stylesheets/galleryGrid.scss";
import Pageheader from "../components/pageheader";

const Design = ({ data }) => {
  const designItems = data.allContentfulDesign.edges;

  const { heroImage, title, body } = data.contentfulPageHeader;



  return (

    <Layout>
      <SEO title="Design" />
      <Pageheader title={title} image={heroImage} body={body}/>
      <div className="container">
        <div className="row">
          <div className="col-12">
          <nav aria-label="breadcrumb" className="custom-breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="/">Home</a></li>
                <li className="breadcrumb-item active"><a href={typeof window !== 'undefined' ? window.location.origin + '/web/' : ''}>design</a></li>
              </ol>
            </nav>
          </div>
          <div className="col">
            {designItems.map(({ node: post }, index) => (
              <div key={post.id} className="galleryGrid">
                <Link to={post.slug}>
                  <h2 className="text-center">
                    {post.title}
                  </h2>
                  {post.designGallery.slice(0, 1).map((item) => (
                    <img src={item.fixed.src} alt={item.title} className="img-fluid" />
                  ))}
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Design;
export const query = graphql`
  query DesignPageQuery {
    allContentfulDesign(limit: 1000) {
      edges {
        node {
          id
          title
          slug
          designGallery {
            fixed(width: 600, height: 600, quality: 100) {
              src
            }
            file{
              url
            }
            title
            id
          }
        }
      }
    }
    contentfulPageHeader(title: {eq: "design"}) {
      heroImage {
        fixed(width: 2000, height: 500) {
          src
        }
        title
      }
      title
      body {
        body
      }
    }
  }
`;
